(function () {
	this.language = {};
}).call(window);

(function () {
	this.translate = (strings, values) => {
		const templateString = strings.join('[#]');
		let translatedStrings = strings;

		const translatedTemplateString = this.getTranslation(templateString);
		if (translatedTemplateString) {
			translatedStrings = translatedTemplateString.split('[#]');
		} else if (translatedTemplateString === false) {
			// Add to the translation table
			this.addToTranslationTable(templateString);
		}

		let str = '';
		translatedStrings.forEach((string, i) => {
			str += string;
			if (values[i]) {
				str += values[i];
			}
		});
		return str;
	};

	this.addToTranslationTable = (template) => {
		// console.log('addToTranslationTable: '+template);
		if (app && app.socket) {
			app.socket.emit('language', { add: template, page: app.page });
		}
		// Add to local translation table, so we won't keep requesting the same
		const language = document.documentElement.lang || 'en';
		this[language][template] = null;
	};

	this.getTranslation = (templateString) => {
		const language = document.documentElement.lang || 'en';
		if (!this[language]) {
			return null;
		}

		if (Object.prototype.hasOwnProperty.call(this[language], templateString)) {
			return this[language][templateString];
		} else {
			return false;
		}
	};
}).call(window.language);

// eslint-disable-next-line no-unused-vars
function translate(strings, ...values) {
	if (typeof strings === 'string' || strings instanceof String) {
		return window.language.translate([strings], []);
	} else {
		return window.language.translate(strings, values);
	}
}
