// Add app to the global scope
(function (window) {
	window.app = {
		version: document.body.getAttribute('data-version'),
	};

	const submitbutton = document.getElementById('submitbutton');
	const terms = document.getElementById('terms');
	const needValidation = document.getElementById('needValidation');

	if (terms) {
		if (needValidation) {
			needValidation.style.display = 'none';
		}

		submitbutton.style.display = 'none';
		const termsAccept = document.getElementById('terms-accept');
		const termsDecline = document.getElementById('terms-decline');
		termsDecline.addEventListener('click', (e) => {
			e.preventDefault();
			app.functions.removeCookie('token');
			location.href = '/';
		});
		termsAccept.addEventListener('click', (e) => {
			if (needValidation) {
				e.preventDefault();
				needValidation.style.display = 'inline-block';
				terms.style.display = 'none';
				submitbutton.style.display = 'block';
			}
		});
	}

	const buttons = document.querySelectorAll('.cards button');
	if (buttons) {
		buttons.forEach((button) => {
			button.addEventListener('click', (e) => {
				e.preventDefault();
				const id = button.getAttribute('data-id');
				const type = button.getAttribute('data-type');
				if (id && type) {
					const cardItem = button.closest('.card-item');
					if (cardItem) {
						cardItem.querySelector('input').value = type;
						const status = cardItem.querySelector('.card-status');
						if (type == 'accepted') {
							status.className = 'card-status card-status-online';
							status.querySelector('span').innerHTML = 'Accepted';
						} else {
							status.className = 'card-status card-status-error';
							status.querySelector('span').innerHTML = 'Declined';
						}
					}
				}
			});
		});
	}
})(window);
