(function () {
	this.app = this.app || {};
	this.app.functions = this.app.functions || {};
	this.app.functions.csv = this.app.functions.csv || {};
	this.app.functions.xls = this.app.functions.xls || {};
}).call(window);

(function () {
	this.changeTimeZone = (timezone) => {
		const timeZoneOffsetLocal = moment.tz(app.getTimeZone()).utcOffset();
		const timeZoneOffsetCalendar = moment.tz(timezone).utcOffset();
		return timeZoneOffsetLocal - timeZoneOffsetCalendar;
	};

	this.getDeviceToken = () => {
		if (window.MyTPWeb && window.MyTPWeb.getDeviceToken) {
			return window.MyTPWeb.getDeviceToken();
		} else {
			return null;
		}
	};

	this.smallScreen = () => {
		return document.body.clientWidth < 900;
	};

	this.isiPhone = () => {
		if (navigator && navigator.platform && (navigator.platform.indexOf('iPhone') != -1 || navigator.platform.indexOf('iPod') != -1)) {
			return true;
		} else {
			return false;
		}
	};

	this.getTemplateFromId = (elementid) => {
		if (!elementid || elementid.indexOf('/') === -1) {
			return null;
		}
		return elementid.split('/')[0] + '/view';
	};

	this.getTypeFromId = (id) => {
		if (id && id.indexOf('/') > -1) {
			return id.split('/')[0];
		} else {
			return null;
		}
	};

	this.getNumberFromId = (id) => {
		if (id && id.indexOf('/') > -1) {
			return id.split('/')[1];
		} else {
			return null;
		}
	};

	this.isElementInViewport = (el, offset = 0) => {
		const rect = el.getBoundingClientRect();
		return rect.top >= 0 && rect.left >= 0 && rect.top + offset <= (window.innerHeight || document.documentElement.clientHeight);
	};

	this.compare = (arr1, arr2) => {
		if (arr1.length != arr2.length) {
			return arr2.length - arr1.length;
		}

		for (let i = 0; i < arr1.length; i++) {
			/* First sort on integer value!! Warning Arango does not do this!
			const i1 = parseInt(arr1[i]);
			const i2 = parseInt(arr2[i]);

			if (!isNaN(i1) && !isNaN(i2)) {
				if (i1 !== i2) {
					return i1 - i2;
				}
			}
			*/

			if (typeof arr1[i] === 'string' && typeof arr2[i] === 'string') {
				const lowerCompare = this.compareStrings(arr1[i].toLowerCase(), arr2[i].toLowerCase());
				if (lowerCompare != 0) {
					return lowerCompare;
				}
			}
			const tmpCompare = this.compareStrings(arr1[i], arr2[i]);
			if (tmpCompare != 0) {
				return tmpCompare;
			}
		}
		return 0;
	};

	this.fromCamelCase = (string) => {
		if (typeof string !== 'string') return '';
		return (
			string
				.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? ' ' : '') + $.toLowerCase())
				// uppercase the first character
				.replace(/^./, (str) => str.toUpperCase())
		);
	};

	this.toCamelCase = (string) => {
		if (typeof string !== 'string') return '';
		return string.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
			if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
			return index == 0 ? match.toLowerCase() : match.toUpperCase();
		});
	};

	this.ucfirst = (s) => {
		if (typeof s !== 'string') return '';
		return s.charAt(0).toUpperCase() + s.slice(1);
	};

	this.copyJSON = (json) => {
		return JSON.parse(JSON.stringify(json));
	};

	this.sortJSON = (objectToSort) => {
		return Object.keys(objectToSort)
			.sort()
			.reduce((r, k) => ((r[k] = objectToSort[k]), r), {});
	};

	this.compareJSON = (json1, json2) => {
		const json1sorted = this.sortJSON(json1);
		const json2sorted = this.sortJSON(json2);
		return JSON.stringify(json1sorted) === JSON.stringify(json2sorted);
	};

	this.compareStrings = (a, b) => {
		return a > b ? 1 : a < b ? -1 : 0;
	};

	this.localDateShortFormatMoment = () => {
		// prettier-ignore
		switch (app.preferences.dateFormat || 'd-m-Y') {
			case 'm/d/Y': return 'MM/DD';
			case 'Y-m-d': return 'MM-DD';
			case 'd-m-Y':
			default: return 'DD-MM';
		}
	};

	this.localDateFormatMoment = () => {
		// prettier-ignore
		switch (app.preferences.dateFormat || 'd-m-Y') {
			case 'm/d/Y': return 'MM/DD/YYYY';
			case 'Y-m-d': return 'YYYY-MM-DD';
			case 'd-m-Y':
			default: return 'DD-MM-YYYY';
		}
	};

	this.localTimeFormatMoment = () => {
		// prettier-ignore
		switch (app.preferences.timeFormat || '24h') {
			case '12h': return 'hh:mm A';
			case '24h':
			default: return 'HH:mm';
		}
	};

	this.localDateTimeFormatMoment = () => {
		return this.localDateFormatMoment() + ' ' + this.localTimeFormatMoment();
	};

	this.localDateFormat = () => {
		return app.preferences.dateFormat || 'd-m-Y';
	};

	this.localDateTimeFormat = () => {
		return this.localDateFormat() + ' ' + this.localTimeFormat();
	};

	this.localTimeFormat = () => {
		return app.preferences.timeFormat != '12h' ? 'H:i' : 'G:i K';
	};

	this.localTime = (date) => {
		let dateTime = date;
		if (typeof date === 'string' || typeof date === 'number') {
			dateTime = new Date(parseInt(dateTime));
		}

		if (dateTime && typeof dateTime === 'object') {
			return flatpickr.formatDate(dateTime, this.localTimeFormat());
		} else {
			return 'Unknown';
		}
	};

	this.regularDateTime = (date) => {
		let dateTime = date;
		if (typeof date === 'string' || typeof date === 'number') {
			dateTime = new Date(parseInt(dateTime));
		}

		if (dateTime && typeof dateTime === 'object') {
			return flatpickr.formatDate(dateTime, 'Y-m-d H:i');
		} else {
			return 'Unknown';
		}
	};

	this.localDateTime = (date) => {
		let dateTime = date;
		if (typeof date === 'string' || typeof date === 'number') {
			dateTime = new Date(parseInt(dateTime));
		}

		if (dateTime && typeof dateTime === 'object') {
			return flatpickr.formatDate(dateTime, this.localDateTimeFormat());
		} else {
			return 'Unknown';
		}
	};

	this.localDate = (date) => {
		let dateTime = date;
		if (typeof date === 'string' || typeof date === 'number') {
			dateTime = new Date(dateTime);
		}

		if (dateTime && typeof dateTime === 'object') {
			return flatpickr.formatDate(dateTime, this.localDateFormat());
		} else {
			return 'Unknown';
		}
	};

	this.appendLeadingZeroes = (n) => {
		if (n < 10) {
			return '0' + n;
		}
		return n;
	};

	const sessionCookies = ['io', 'token', 'initUrl', 'oldToken', 'viewAsUser'];
	const storeAsCookie = [
		'organisationid',
		'autologin',
		'lang',
		'skin',
		'oldBrowser',
		'app-name',
		'app-version',
		'app-platform',
		'app-skin',
		'app-platform-version',
	];

	this.getCookieName = (name) => {
		return name.replace(/\//g, '_');
	};

	this._hasStorage = null;
	this.hasStorage = () => {
		if (this._hasStorage !== null) {
			return this._hasStorage;
		}
		// The iOS app did not allow usage of localStorage before 1.4.3!!
		if (app.isiOS === true && app.functions.compareAppVersion('1.4.3') === -1) {
			this._hasStorage = false;
			return false;
		}
		const uid = new Date();
		let result;
		try {
			localStorage.setItem(uid, uid);
			result = localStorage.getItem(uid) == uid;
			localStorage.removeItem(uid);

			if (!Object.prototype.hasOwnProperty.call(localStorage, 'hasMigratedCookies')) {
				// Migrate cookies to local storage
				const decodedCookie = decodeURIComponent(document.cookie);
				const ca = decodedCookie.split(';');
				const d = new Date();
				const expires = 'expires=' + d.toUTCString();

				for (let i = 0; i < ca.length; i++) {
					let c = ca[i];
					while (c.charAt(0) == ' ') {
						c = c.substring(1);
					}

					const split = c.split('=');
					if (split.length == 2) {
						if (
							sessionCookies.indexOf(split[0]) === -1 &&
							storeAsCookie.indexOf(split[0]) === -1 &&
							split[0].indexOf('vjs-') === -1 &&
							split[0].indexOf('pdfjs.') === -1
						) {
							localStorage.setItem(split[0], split[1]);
							document.cookie = split[0] + '=;' + expires + ';path=/';
						}
					}
				}
				localStorage.setItem('hasMigratedCookies', app.version);
				this.localStorageChange();
			}

			this._hasStorage = result;
			return result;
		} catch {
			this._hasStorage = false;
			return false;
		}
	};

	this.useCookie = (name) => {
		return this.hasStorage() === false || sessionCookies.indexOf(name) > -1 || storeAsCookie.indexOf(name) > -1;
	};

	this.setCookie = (cname, value, exdays) => {
		cname = this.getCookieName(cname);

		if (this.useCookie(cname)) {
			// Store as a cookie!
			const exdate = new Date();
			exdate.setDate(exdate.getDate() + exdays);
			const cvalue = encodeURIComponent(value) + (exdays == null ? '' : '; expires=' + exdate.toUTCString());
			document.cookie = cname + '=' + cvalue + '; path=/;';
		} else {
			// Store in local storage
			localStorage.setItem(cname, value);
			this.localStorageChange();
		}
	};

	this.getCookie = (cname, defaultValue) => {
		cname = this.getCookieName(cname);
		if (this.useCookie(cname)) {
			const name = cname + '=';
			const decodedCookie = decodeURIComponent(document.cookie);
			const ca = decodedCookie.split(';');
			for (let i = 0; i < ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) == ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) == 0) {
					return c.substring(name.length, c.length);
				}
			}
		} else {
			const value = localStorage.getItem(cname);
			if (value !== null) {
				return value;
			}
		}

		return defaultValue;
	};

	this.localStorageChange = () => {
		app.send('localStorageChange', JSON.stringify(localStorage));
	};

	this.removeCookie = (cname) => {
		cname = this.getCookieName(cname);
		if (this.useCookie(cname)) {
			const d = new Date();
			const expires = 'expires=' + d.toUTCString();
			document.cookie = cname + '=;' + expires + ';path=/';
		} else {
			localStorage.removeItem(cname);
			this.localStorageChange();
		}
	};

	this.refreshCookies = () => {
		this.delay(
			() => {
				const decodedCookie = decodeURIComponent(document.cookie);
				const ca = decodedCookie.split(';');
				for (let i = 0; i < ca.length; i++) {
					let c = ca[i];
					while (c.charAt(0) == ' ') {
						c = c.substring(1);
					}

					const split = c.split('=');
					if (split.length == 2) {
						if (sessionCookies.indexOf(split[0]) == -1) {
							this.setCookie(split[0], split[1], 365);
						}
					}
				}
			},
			10000,
			'refreshCookies',
		);
	};

	this.cleanSessionCookies = () => {
		const decodedCookie = decodeURIComponent(document.cookie);
		const ca = decodedCookie.split(';');

		const d = new Date();
		const expires = 'expires=' + d.toUTCString();

		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}

			const split = c.split('=');
			if (split.length == 2) {
				if (sessionCookies.indexOf(split[0]) > -1) {
					document.cookie = split[0] + '=;' + expires + ';path=/';
				}
			}
		}
	};

	this.humanTime = (seconds) => {
		const sec_num = parseInt(seconds, 10);
		const hours = this.appendLeadingZeroes(Math.floor(sec_num / 3600));
		const minutes = this.appendLeadingZeroes(Math.floor((sec_num - hours * 3600) / 60));
		const seconds2 = this.appendLeadingZeroes(sec_num - hours * 3600 - minutes * 60);
		return hours + ':' + minutes + ':' + seconds2;
	};

	this.durationFromSeconds = (seconds) => {
		const sec_num = parseInt(seconds, 10);

		const days = Math.floor(sec_num / 86400);
		const hours = Math.floor((sec_num - days * 86400) / 3600);
		const minutes = Math.floor((sec_num - hours * 3600 - days * 86400) / 60);
		const seconds2 = sec_num - days * 86400 - hours * 3600 - minutes * 60;

		if (days > 0) {
			return translate`${days} days ${hours} hours ${minutes} minutes ${seconds2} seconds`;
		} else if (hours > 0) {
			return translate`${hours} hours ${minutes} minutes ${seconds2} seconds`;
		} else if (minutes > 0) {
			return translate`${minutes} minutes ${seconds2} seconds`;
		} else {
			return translate`${seconds2} seconds`;
		}
	};

	this.jsonToQueryString = (obj) => {
		const keys = Object.keys(obj);
		if (keys.length == 0) {
			return '';
		}

		return (
			'?' +
			keys
				.reduce((a, k) => {
					a.push(k + '=' + encodeURIComponent(obj[k]));
					return a;
				}, [])
				.join('&')
		);
	};

	this.QueryStringToJson = (queryString) => {
		if (queryString.length == 0) {
			return {};
		}
		return decodeURI(queryString)
			.replace('?', '')
			.split('&')
			.map((param) => param.split('='))
			.reduce((values, [key, value]) => {
				values[key] = decodeURIComponent(value);
				return values;
			}, {});
	};

	// app.functions.get(user,'some.nested.value.deep.away','not found')
	this.get = (obj, key, defaultValue) => {
		return key.split('.').reduce((o, x) => {
			return typeof o == 'undefined' || o === null ? defaultValue : o[x];
		}, obj);
	};

	this.humanFileSize = (bytes, decimals = 1, k = 1000) => {
		if (bytes === 0) return '0 Bytes';
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
	};

	this.quota = (start, end, steps) => {
		const array = [];
		for (let i = start; i < end; i += steps) {
			array.push(i);
		}
		return array;
	};

	this.generateQuotas = (current, max) => {
		current = current ? parseInt(current) : null;
		max = max ? parseInt(max) : null;

		let quotas = this.quota(1000, 20000, 1000);
		quotas.push.apply(quotas, this.quota(20000, 100000, 5000));
		quotas.push.apply(quotas, this.quota(100000, 1000000, 10000));
		quotas.push.apply(quotas, this.quota(1000000, 25000001, 500000));

		if (max && quotas.indexOf(max) == -1) {
			quotas.push(max);
		}

		if (max) {
			quotas = quotas.filter((v) => v <= max);
		}

		if (current && quotas.indexOf(current) == -1) {
			quotas.push(current);
		}

		quotas.sort((a, b) => a - b);
		return quotas;
	};

	this.generateQuotaOptions = (current, max) => {
		const options = [{ value: '', title: 'No quota' }];
		return options.concat(
			this.generateQuotas(current, max).map((q) => {
				return { value: q, title: this.humanFileSize(q * 1000000, 1, 1000) };
			}),
		);
	};

	const delayTimers = {};
	this.delay = (callback, ms, queue) => {
		clearTimeout(delayTimers[queue]);
		if (ms > 0) {
			delayTimers[queue] = setTimeout(callback, ms);
		}
	};

	this.delayPromise = (t, v) => {
		return new Promise((resolve) => {
			setTimeout(resolve.bind(null, v), t);
		});
	};

	// Get a valie from the element object (multi dimensional)
	this.getValueForElement = (element, key) => {
		if (element && key && key.indexOf('[') > -1) {
			const keys = key.split(']').join('').split('[');
			let result = JSON.parse(JSON.stringify(element));

			while (keys.length > 0) {
				const checkKey = keys.shift();
				if (result && Object.prototype.hasOwnProperty.call(result, checkKey)) {
					if (keys.length > 0) {
						result = result[checkKey];
					} else {
						return result[checkKey];
					}
				} else {
					return null;
				}
			}
			return null;
		} else if (element && key && Object.prototype.hasOwnProperty.call(element, key)) {
			return element[key];
		} else {
			return null;
		}
	};

	this.getPixelRatio = () => {
		if (
			window.screen.systemXDPI !== undefined &&
			window.screen.logicalXDPI !== undefined &&
			window.screen.systemXDPI > window.screen.logicalXDPI
		) {
			return window.screen.systemXDPI / window.screen.logicalXDPI;
		} else if (window.devicePixelRatio !== undefined) {
			return window.devicePixelRatio;
		} else {
			return 1;
		}
	};

	this.getBrowser = () => {
		const ua = navigator.userAgent;
		let tem;
		let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
		if (/trident/i.test(M[1])) {
			tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
			return { name: 'IE', version: tem[1] || '' };
		}
		if (M[1] === 'Chrome') {
			tem = ua.match(/\bOPR|Edge\/(\d+)/);
			if (tem != null) {
				return { name: 'Opera', version: tem[1] };
			}
		}
		M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
		if ((tem = ua.match(/version\/(\d+)/i)) != null) {
			M.splice(1, 1, tem[1]);
		}
		return {
			name: M[0],
			version: M[1],
		};
	};

	this.uuid = () => {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
			const r = (Math.random() * 16) | 0,
				v = c == 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	};

	this.hexToRGB = (color, alpha = 255) => {
		const hex = color.replace('#', '');
		const c_r = parseInt(hex.substr(0, 2), 16);
		const c_g = parseInt(hex.substr(2, 2), 16);
		const c_b = parseInt(hex.substr(4, 2), 16);
		return 'rgba(' + c_r + ',' + c_g + ',' + c_b + ',' + alpha + ')';
	};

	this.colorIsLight = (color) => {
		if (color && color.indexOf('rgb(') === 0) {
			return this.colorIsLightRGB(color);
		} else if (color.indexOf('#') === 0) {
			const hex = color.replace('#', '');
			const r = parseInt(hex.substr(0, 2), 16);
			const g = parseInt(hex.substr(2, 2), 16);
			const b = parseInt(hex.substr(4, 2), 16);
			return this.colorIsLightCheck(r, g, b);
		} else {
			return false;
		}
	};

	this.colorIsLightCheck = (r, g, b) => {
		const uicolors = [r / 255, g / 255, b / 255];
		const c = uicolors.map((col) => {
			if (col <= 0.03928) {
				return col / 12.92;
			}
			return Math.pow((col + 0.055) / 1.055, 2.4);
		});
		const L = 0.299 * c[0] + 0.587 * c[1] + 0.114 * c[2];
		return L > 0.186 ? true : false;
	};

	this.colorIsLightRGB = (rgb) => {
		if (rgb && rgb.indexOf('rgb(') === 0) {
			let colors = rgb.replace('rgb(', '');
			colors = colors.replace(')', '');
			colors = colors.split(',');

			const r = parseInt(colors[0]);
			const g = parseInt(colors[1]);
			const b = parseInt(colors[2]);

			return this.colorIsLightCheck(r, g, b);
		}
		return false;
	};

	this.stripHTML = (html) => {
		const doc = new DOMParser().parseFromString(html, 'text/html');
		return doc.body.textContent || '';
	};

	this.compareAppVersion = (versionToCheck) => {
		let appVersion = document.body.getAttribute('data-app-version');
		if (appVersion) {
			appVersion = appVersion.replace('v', '').split(' ')[0];
			return this.versionCompare(appVersion, versionToCheck);
		}
		return null;
	};

	this.versionCompare = (v1, v2, options) => {
		const lexicographical = options && options.lexicographical;
		const zeroExtend = options && options.zeroExtend;
		let v1parts = v1.split('.');
		let v2parts = v2.split('.');

		function isValidPart(x) {
			return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
		}

		if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
			return NaN;
		}

		if (zeroExtend) {
			while (v1parts.length < v2parts.length) v1parts.push('0');
			while (v2parts.length < v1parts.length) v2parts.push('0');
		}

		if (!lexicographical) {
			v1parts = v1parts.map(Number);
			v2parts = v2parts.map(Number);
		}

		for (let i = 0; i < v1parts.length; ++i) {
			if (v2parts.length == i) {
				return 1;
			}

			if (v1parts[i] == v2parts[i]) {
				continue;
			} else if (v1parts[i] > v2parts[i]) {
				return 1;
			} else {
				return -1;
			}
		}

		if (v1parts.length != v2parts.length) {
			return -1;
		}

		return 0;
	};
}).call(app.functions);

(function () {
	// TODO: Rewrite importCSV to importXLS
	this.importCSV = (file, forcedFormat, expectingStrings) => {
		const data = [];
		const allLines = file.split(/\r\n|\n|\r/);
		// Reading line by line

		if (allLines.length === 0) {
			return null;
		}

		const possibleEnclosures = ["'", '"'];

		const commaDelimiterCount = (file.match(/,/g) || []).length;
		const dotcommaDelimiterCount = (file.match(/;/g) || []).length;
		const tabDelimiterCount = (file.match(/\t/g) || []).length;

		const highestDelimiterCount = Math.max(commaDelimiterCount, dotcommaDelimiterCount, tabDelimiterCount);

		let foundDelimiter = ',';
		if (highestDelimiterCount === commaDelimiterCount) {
			foundDelimiter = ',';
		} else if (highestDelimiterCount === dotcommaDelimiterCount) {
			foundDelimiter = ';';
		} else if (highestDelimiterCount === tabDelimiterCount) {
			foundDelimiter = '\t';
		}

		const firstLineCount = allLines[0].split(foundDelimiter).length;
		if (firstLineCount == 0) {
			return null;
		}

		allLines.forEach((line) => {
			const items = line.split(foundDelimiter);
			if (items.length === firstLineCount) {
				const newItems = [];
				items.forEach((item, index) => {
					let parsedType = null;
					item = String(item);
					if (item[0] === item[item.length - 1] && possibleEnclosures.indexOf(item[0]) > -1) {
						item = item.substring(1, item.length - 1);
					}
					const dateItem = Date.parse(item.replace(' ', 'T'));
					if (!isNaN(dateItem) && dateItem > 0) {
						item = dateItem;
						parsedType = Date;
					} else {
						const numberItem = parseFloat(item);
						if (expectingStrings !== true && !isNaN(item.replace(',', '.')) && !isNaN(numberItem)) {
							item = parseFloat(item.replace(',', '.'));
							parsedType = Number;
						} else {
							item = item.trim();
							parsedType = item.constructor;
						}
					}

					if (parsedType && forcedFormat && forcedFormat[index]) {
						if (parsedType === forcedFormat[index]) {
							newItems.push(item);
						}
					} else {
						newItems.push(item);
					}
				});
				if (newItems.length === firstLineCount) {
					data.push(newItems);
				}
			}
		});
		return data;
	};
}).call(app.functions.csv);

(function () {
	this.autoWidthArray = (data) => {
		/* calculate column width */
		const minWidth = 5;

		const howManyColumns = data.reduce((prev, curr) => {
			return curr.length > prev ? curr.length : prev;
		}, 0);

		let currentWidth = minWidth;
		const worksheetCols = [];

		for (const index in Array.from({ length: howManyColumns })) {
			for (const curr of data) {
				if (curr[index]) {
					currentWidth = curr[index].length > currentWidth ? curr[index].length : currentWidth;
				}
			}

			worksheetCols.push({
				width: currentWidth,
			});

			currentWidth = minWidth;
		}

		return worksheetCols;
	};

	this.fixNumbersArray = (values) => {
		values.forEach((row) => {
			row.forEach((item, i) => {
				if (!isNaN(item)) {
					const numberValue = parseFloat(item);
					if (!isNaN(numberValue)) {
						row[i] = numberValue;
					}
				}
			});
		});
		return values;
	};

	this.exportArray = (values, headers, fileName) => {
		if (values.length === 0) {
			app.alert.warning(translate`Export`, translate`No data available for export`);
			return;
		}

		const data = this.fixNumbersArray(values);
		if (headers) {
			data.unshift(headers);
		}

		const worksheet = XLSX.utils.aoa_to_sheet(data, {});
		worksheet['!cols'] = this.autoWidthArray(data);

		this.writeSheet(worksheet, fileName);
	};

	this.writeSheet = (worksheet, fileName) => {
		const bookTypes = {
			xlsx: 'Excel 2007+ XML Format (xlsx)',
			xls: 'Excel 97-2004 Workbook Format (xls)',
			numbers: 'Numbers 3.0+ Spreadsheet (numbers)',
			ods: 'OpenDocument Spreadsheet (ods)',
			csv: 'Comma Separated Values (csv)',
			txt: 'UTF-16 Unicode Text (txt)',
		};

		const defaultFormat = app.functions.getCookie('export-format', 'xlsx');

		return Swal.fire({
			title: translate`Export`,
			text: translate`Please select the file format`,
			input: 'select',
			icon: 'question',
			showCancelButton: true,
			showConfirmButton: true,
			inputOptions: bookTypes,
			inputValue: defaultFormat,
		})
			.then((result) => {
				if (!result.value) return;
				const format = result.value;
				const options = { compression: true, bookType: format };
				if (format !== defaultFormat) {
					app.functions.setCookie('export-format', format);
				}

				// Export to numbers need a large payload, no need to load if not needed.
				if (format === 'numbers') {
					if (this.XLSX_ZAHL_PAYLOAD) {
						options.numbers = this.XLSX_ZAHL_PAYLOAD;
					} else {
						return fetch('/vendors/sheetjs/xlsx_zahl_payload')
							.then((payload) => payload.text())
							.then((payload) => {
								this.XLSX_ZAHL_PAYLOAD = payload;
								options.numbers = payload;
								return options;
							});
					}
				}

				return options;
			})
			.then((options) => {
				const filenameWithExtension = `${fileName || 'export'}.${options.bookType}`;
				this.writeSheetWithOptions(worksheet, filenameWithExtension, options);
			});
	};

	this.writeSheetWithOptions = (worksheet, filenameWithExtension, options) => {
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet);
		XLSX.writeFile(workbook, filenameWithExtension, options);
	};
}).call(app.functions.xls);
